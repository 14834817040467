// 月別 投稿一覧ページ
// ref: https://mtane0412.com/implementing-yearly-and-montly-archives-in-gatsby-and-contentful/
import React from "react";
import { jsx, Heading } from "theme-ui"
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import styled from '@emotion/styled'
import Layout   from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import SEO from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/seo"

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: 1px solid #ccc;
  margin: 1em;
  vertical-align: middle;
  align-items: center;
  h3 {
    display: block;
    flex: 2;
    margin: 0;
    padding: 0;
    a {
      text-decoration: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    flex: 2;
    li {
      display: block;
      background-color: #eee;
      border-radius: 10px;
      margin: .25em;
      a {
        display: block;
        padding: .5em;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        &:hover {
          background-color: #ccc;
        }
      }
    }
  }
`

class ArchivesIndex extends React.Component {
  render() {
    const posts = get(this, "props.data.allMdxPost.edges");
    const totalCounts = {};
    const years = new Set();
    const yearMonths = new Set();

    posts.forEach(post => {
      const { year, yearMonth } = post.node;

      // 年別、月別のtotalCountを追加
      if (!totalCounts.hasOwnProperty(year)) totalCounts[year] = 0;
      if (!totalCounts.hasOwnProperty(yearMonth)) totalCounts[yearMonth] = 0;
      totalCounts[year] += 1;
      totalCounts[yearMonth] += 1;

      // years, yearMonths Set作成
      years.add(year);
      yearMonths.add(yearMonth);

    })

    const yearList = Array.from(years);
    const yearMonthList = Array.from(yearMonths);

    return (
      <Layout location={this.props.location}>
        <SEO title="Archives" desc="月別アーカイブ" noindex />
        <Heading variant="styles.h2">{`月別アーカイブ`}</Heading>
          {yearList.map(year => {
            return (
              <div>
                  <h3 key={`header${year}`}><Link to={`/archives/${year}/`}>{year}年 ({totalCounts[year]})</Link></h3>
                  <ul key={`list${year}`} className={"article-list"}>
                    {yearMonthList.map(yearMonth => {
                      if (year === yearMonth.split('/')[0]) {
                        return (
                          <li key={yearMonth}>
                            <Link to={`/archives/${yearMonth}/`}>{yearMonth} ({totalCounts[yearMonth]})</Link>
                          </li>
                        )
                      }
                    })}
                  </ul>
              </div>
            )
          })}
      </Layout>
    );
  }
}

export default ArchivesIndex;

export const pageQuery = graphql`
  query ArchiveIndexQuery {
    allMdxPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          year: date(formatString: "YYYY")
          yearMonth: date(formatString: "YYYY/MM")
        }
      }
    }
  }
`;
